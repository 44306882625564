.signin-header {
  margin-bottom: 4px;
  .signin-logo {
    display: block;
    height: 30px;
    margin: 0 auto;
  }
  .signin-title {
    text-align: center;
    line-height: 25px;
    margin: 0;
    font-weight: 600;
  }
}

@styleGuide-color-primary: #30206e;@styleGuide-color-secondary: #57ccd9;@styleGuide-color-spotlight: rgba(48, 32, 110, 0.1);@styleGuide-color-text: #5B5B58;@styleGuide-color-success: #07c81a;@styleGuide-color-error: #f61515;@styleGuide-protocol-border-card: #30206e;@styleGuide-protocol-bg-card: rgba(48, 32, 110, 0.1);@styleGuide-table-header-color: rgba(48, 32, 110, 0.1);@styleGuide-color-menu: @styleGuide-color-primary;@styleGuide-hover-menu: @styleGuide-color-secondary;@styleGuide-borderCard-color: @styleGuide-color-primary;@styleGuide-primary-btn-text-color: #ffffff;@styleGuide-primary-btn-stroke-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-shadow: 2px 2px 6px @styleGuide-color-primary;@styleGuide-secondary-btn-text-color: @styleGuide-color-primary;@styleGuide-secondary-btn-stroke-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-shadow: 0px 2px 2px #038FDE;@styleGuide-toogle-label-color: @styleGuide-color-primary;