@import "../../theme/variables.less";
.labelWrapper {
  display: flex;
  width: 100%;
  .labelItem {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    padding: 7.5px 7.5px 7.5px 0;
  }
  .fieldWrapper {
    padding: 7.5px 0 7.5px 7.5px;
    width: 100%;
    min-width: 150px;
    max-width: 400px;
    .ant-form-item {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.vertical-labelWrapper {
  flex-direction: column;
  .labelItem {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    text-align: left;
    padding: 0;
    color: @text-color-secondary;
  }
  .fieldWrapper {
    padding: 0 0 2px;
    width: 100%;
  }
}

@media (max-width: 850px) {
  .labelWrapper {
    flex-direction: column;
    padding: 5px 0;
    .labelItem {
      justify-content: flex-start;
      padding: 0 0 10px 0;
      text-align: left;
    }
    .fieldWrapper {
      padding: 0;
    }
  }
}

@styleGuide-color-primary: #30206e;@styleGuide-color-secondary: #57ccd9;@styleGuide-color-spotlight: rgba(48, 32, 110, 0.1);@styleGuide-color-text: #5B5B58;@styleGuide-color-success: #07c81a;@styleGuide-color-error: #f61515;@styleGuide-protocol-border-card: #30206e;@styleGuide-protocol-bg-card: rgba(48, 32, 110, 0.1);@styleGuide-table-header-color: rgba(48, 32, 110, 0.1);@styleGuide-color-menu: @styleGuide-color-primary;@styleGuide-hover-menu: @styleGuide-color-secondary;@styleGuide-borderCard-color: @styleGuide-color-primary;@styleGuide-primary-btn-text-color: #ffffff;@styleGuide-primary-btn-stroke-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-shadow: 2px 2px 6px @styleGuide-color-primary;@styleGuide-secondary-btn-text-color: @styleGuide-color-primary;@styleGuide-secondary-btn-stroke-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-shadow: 0px 2px 2px #038FDE;@styleGuide-toogle-label-color: @styleGuide-color-primary;