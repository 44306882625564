@import "@/theme/variables";

.pageLayoutHeader {
  position: relative;
  padding: 0;
  margin-bottom: 30px;
  height: 40px;

  .pageLayoutCTA {
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .pageLayoutTag {
    margin-left: auto;
  }

  .pageLayoutTitle {
    margin: 0;
    font-weight: 700;
    text-align: center;
    line-height: 38px;
  }

  .layoutBackButton {
    margin: 0 !important;
    padding: 0px !important;
    color: @primary-color !important;
    height: max-content !important;
    &:hover,
    &:focus {
      background-color: @white-color !important;
      font-weight: bold !important;
      transition: none;
    }
  }

  @media screen and (max-width: 580px) {
    .layoutBackButton {
      left: 10px;
    }
    .pageLayoutCTA {
      top: 50px;
      padding: 0 10px;
      z-index: 1;
    }
  }
}

@styleGuide-color-primary: #30206e;@styleGuide-color-secondary: #57ccd9;@styleGuide-color-spotlight: rgba(48, 32, 110, 0.1);@styleGuide-color-text: #5B5B58;@styleGuide-color-success: #07c81a;@styleGuide-color-error: #f61515;@styleGuide-protocol-border-card: #30206e;@styleGuide-protocol-bg-card: rgba(48, 32, 110, 0.1);@styleGuide-table-header-color: rgba(48, 32, 110, 0.1);@styleGuide-color-menu: @styleGuide-color-primary;@styleGuide-hover-menu: @styleGuide-color-secondary;@styleGuide-borderCard-color: @styleGuide-color-primary;@styleGuide-primary-btn-text-color: #ffffff;@styleGuide-primary-btn-stroke-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-shadow: 2px 2px 6px @styleGuide-color-primary;@styleGuide-secondary-btn-text-color: @styleGuide-color-primary;@styleGuide-secondary-btn-stroke-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-shadow: 0px 2px 2px #038FDE;@styleGuide-toogle-label-color: @styleGuide-color-primary;