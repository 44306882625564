@import "@/theme/variables";

.broker-home {
  position: relative;
  display: grid;
  place-items: center;
  height: calc(100vh - 82px);
  &::after {
    content: "";
    position: absolute;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("../../assets/images/background_login.png");
    background-size: cover;
  }
  .broker-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    height: 210px;
    background: white;
    padding: 27px 0;
    z-index:2;
    h3 {
      font-size: 1.5em;
      color: @text-color-secondary;
    }
    .broker-icon {
      margin-top: 40px;
      font-size: 4em;
      color: @nav-bar-bg;
    }
  }
}

@styleGuide-color-primary: #30206e;@styleGuide-color-secondary: #57ccd9;@styleGuide-color-spotlight: rgba(48, 32, 110, 0.1);@styleGuide-color-text: #5B5B58;@styleGuide-color-success: #07c81a;@styleGuide-color-error: #f61515;@styleGuide-protocol-border-card: #30206e;@styleGuide-protocol-bg-card: rgba(48, 32, 110, 0.1);@styleGuide-table-header-color: rgba(48, 32, 110, 0.1);@styleGuide-color-menu: @styleGuide-color-primary;@styleGuide-hover-menu: @styleGuide-color-secondary;@styleGuide-borderCard-color: @styleGuide-color-primary;@styleGuide-primary-btn-text-color: #ffffff;@styleGuide-primary-btn-stroke-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-shadow: 2px 2px 6px @styleGuide-color-primary;@styleGuide-secondary-btn-text-color: @styleGuide-color-primary;@styleGuide-secondary-btn-stroke-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-shadow: 0px 2px 2px #038FDE;@styleGuide-toogle-label-color: @styleGuide-color-primary;