@import "../../../theme/variables.less";
.registerSection {
  .documentType-label {
    font-size: 18px;
  }

  .ant-upload-drag {
    border: 1px solid @primary-color !important;
    background-color: #fefefe !important;
    margin: 30px 0 0;

    .ant-upload-btn {
      padding: 0 !important;
      padding: 0px 20px !important;
    }

    .ant-upload-drag-container {
      height: 94px !important;
    }

    .ant-upload-drag-icon {
      padding-right: 10px;
      svg {
        color: @primary-color;
        width: 40px;
        height: 40px;
      }
    }
  }

  .registerManually-label{
    margin-top: 30px;
    a {
      color: @secondary-color !important;
    }
  }

  .registerManually-button{
    margin: 20px 0 10px;
  }
}

@styleGuide-color-primary: #30206e;@styleGuide-color-secondary: #57ccd9;@styleGuide-color-spotlight: rgba(48, 32, 110, 0.1);@styleGuide-color-text: #5B5B58;@styleGuide-color-success: #07c81a;@styleGuide-color-error: #f61515;@styleGuide-protocol-border-card: #30206e;@styleGuide-protocol-bg-card: rgba(48, 32, 110, 0.1);@styleGuide-table-header-color: rgba(48, 32, 110, 0.1);@styleGuide-color-menu: @styleGuide-color-primary;@styleGuide-hover-menu: @styleGuide-color-secondary;@styleGuide-borderCard-color: @styleGuide-color-primary;@styleGuide-primary-btn-text-color: #ffffff;@styleGuide-primary-btn-stroke-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-shadow: 2px 2px 6px @styleGuide-color-primary;@styleGuide-secondary-btn-text-color: @styleGuide-color-primary;@styleGuide-secondary-btn-stroke-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-shadow: 0px 2px 2px #038FDE;@styleGuide-toogle-label-color: @styleGuide-color-primary;