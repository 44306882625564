@import (reference) "../../theme/variables.less";
.signin-form{   
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; 
    width:350px;
    height:300px;
    && .ant-form-item {
        width:100%;
    }
}

.withFooter{
    padding-bottom: 20px !important;
}

.signin-user-toogle{
    position: absolute;
    left: 0;
    top: 57px;
    max-width: 366px;
    
    .user-toogle-label{
        background-color: @toogle-label-color;
        border-radius: 0px 8px 8px 0px;
        padding: 26px 85px 25px;
        margin-bottom: 5px;
        
        h3 {
            color: #FFFFFF;
            line-height: 32px;
            padding: 0;
            margin: 0;
            text-align: center;
        }
    }

    h4 {
        text-align: center;
    }

}

@media screen and (max-width: 760px){
    .signin-user-toogle{
        position: initial;

        .user-toogle-label{
            border-radius: 8px;
        }
    }
}
@styleGuide-color-primary: #30206e;@styleGuide-color-secondary: #57ccd9;@styleGuide-color-spotlight: rgba(48, 32, 110, 0.1);@styleGuide-color-text: #5B5B58;@styleGuide-color-success: #07c81a;@styleGuide-color-error: #f61515;@styleGuide-protocol-border-card: #30206e;@styleGuide-protocol-bg-card: rgba(48, 32, 110, 0.1);@styleGuide-table-header-color: rgba(48, 32, 110, 0.1);@styleGuide-color-menu: @styleGuide-color-primary;@styleGuide-hover-menu: @styleGuide-color-secondary;@styleGuide-borderCard-color: @styleGuide-color-primary;@styleGuide-primary-btn-text-color: #ffffff;@styleGuide-primary-btn-stroke-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-shadow: 2px 2px 6px @styleGuide-color-primary;@styleGuide-secondary-btn-text-color: @styleGuide-color-primary;@styleGuide-secondary-btn-stroke-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-shadow: 0px 2px 2px #038FDE;@styleGuide-toogle-label-color: @styleGuide-color-primary;