.sidebar-menu-item-group{
    .ant-menu-item-group-title{
        font-size: 14px;
        text-transform: uppercase;
        padding-top: 10px !important;
        padding-left: 40px !important;
    }
    ul {
        padding-left: 20px !important;
    }
}
.title-user {
    color:white;
    text-transform: capitalize;
    margin-bottom: 5px;
    line-height: 28px;
}
.title-company {
    color:white;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 38px;
}
.insured-select {
    width:200px;
}

.sidebar-brand {
    display: flex;
    align-items: center;
    height: 72px;
    border-bottom: 1px solid #ffffff;
    padding: 0 62px;
    margin-bottom: 30px;
}

.sidebar-content-menu {
    .ant-menu-submenu-arrow {
        position: relative !important;
        right: 0 !important;
        left: 10px !important;
    }
}

.sidebar-user {
    margin-bottom: 70px;
}

.contact-items-wrapper {
    margin-top: 20px;
    padding-left: 40px;
}
@styleGuide-color-primary: #30206e;@styleGuide-color-secondary: #57ccd9;@styleGuide-color-spotlight: rgba(48, 32, 110, 0.1);@styleGuide-color-text: #5B5B58;@styleGuide-color-success: #07c81a;@styleGuide-color-error: #f61515;@styleGuide-protocol-border-card: #30206e;@styleGuide-protocol-bg-card: rgba(48, 32, 110, 0.1);@styleGuide-table-header-color: rgba(48, 32, 110, 0.1);@styleGuide-color-menu: @styleGuide-color-primary;@styleGuide-hover-menu: @styleGuide-color-secondary;@styleGuide-borderCard-color: @styleGuide-color-primary;@styleGuide-primary-btn-text-color: #ffffff;@styleGuide-primary-btn-stroke-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-shadow: 2px 2px 6px @styleGuide-color-primary;@styleGuide-secondary-btn-text-color: @styleGuide-color-primary;@styleGuide-secondary-btn-stroke-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-shadow: 0px 2px 2px #038FDE;@styleGuide-toogle-label-color: @styleGuide-color-primary;