@import "@/theme/variables";

.dashboard-card-label {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.dashboard-card-content {
  font-size: 24px;
  .dashboard-values {
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
  }
}
.chart-title {
  font-size: 18px;
  margin: 10px 0 0 0;
}

.no-register-label {
  text-align: center;
  padding: 2em 1.5em 1em 1.5em;
  margin: 0;
  color: @text-color-secondary;
  font-size: 1.2rem;
}

.recharts-default-tooltip {
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}

@styleGuide-color-primary: #30206e;@styleGuide-color-secondary: #57ccd9;@styleGuide-color-spotlight: rgba(48, 32, 110, 0.1);@styleGuide-color-text: #5B5B58;@styleGuide-color-success: #07c81a;@styleGuide-color-error: #f61515;@styleGuide-protocol-border-card: #30206e;@styleGuide-protocol-bg-card: rgba(48, 32, 110, 0.1);@styleGuide-table-header-color: rgba(48, 32, 110, 0.1);@styleGuide-color-menu: @styleGuide-color-primary;@styleGuide-hover-menu: @styleGuide-color-secondary;@styleGuide-borderCard-color: @styleGuide-color-primary;@styleGuide-primary-btn-text-color: #ffffff;@styleGuide-primary-btn-stroke-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-color: @styleGuide-color-primary;@styleGuide-primary-btn-hover-shadow: 2px 2px 6px @styleGuide-color-primary;@styleGuide-secondary-btn-text-color: @styleGuide-color-primary;@styleGuide-secondary-btn-stroke-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-color: @styleGuide-color-secondary;@styleGuide-secondary-btn-hover-shadow: 0px 2px 2px #038FDE;@styleGuide-toogle-label-color: @styleGuide-color-primary;